import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
    ENG: {
        translation: {
            ShowSkippedParts: 'Show Skipped Parts',
            exclude_all: 'Exclude all',
            select_all: 'Select all',
            Start: 'Start',
            allParts: 'All Parts',
            Position: 'Position',
            Beam_stand: 'Beam stand',
            Shift: 'Shift',
            tobe_assembled_parts: 'To be assembled parts',
            simulation_info: 'Simulation Info',
            RackPosition: 'Beam position',
            IDLE: 'Waiting...',
            MatchBeamResult: 'MATCHING BEAM RESULTS',
            DownloadFiles: 'Downloading files',
            task_info: 'Task Info',
            CopyToClipboard: 'Task ID was copied to clipboard',
            Logout: 'Logout',
            avg_min_part: 'Average assembly time of a part',
            Quantity_rows: 'Number of rows',
            ContinueTask: 'Continue task',
            PostponeAssembling: 'Postpone assembly',
            Part: 'Part',
            Quantity: 'Quantity',
            openParts: 'Show parts',
            openModelInfo: 'Model info',
            total_parts: 'Total assembled parts',
            status: 'Status',
            general_view: 'General view',
            virtual_view: '3D view',
            scheme: 'Scheme',
            succ_end_but: 'Assembly successful',
            cancel_end_but: 'Assembly is not successful',
            assembly_ended_modal_text: 'Assembly ended. Please select an action',
            weight: 'weight',
            Total_weight: 'Total weight',
            kg: 'kg',
            Total_assembled: 'Total assembled',
            show: 'Show',
            download: 'Download',
            From_date: 'From date',
            To_date: 'to date',
            filter: 'Filter',
            graph3_title: 'RTK efficiency, % (operational minutes/480)',
            graph2_title: 'Number of operational minutes of RTK, min/day',
            graph1_title: 'Total weight and number of assembled beams, pcs/day',
            CurrentAssemblyCount: 'Current number of assemblies',
            AssemblyCountChangeTo: 'Change assembly number to',
            ModelName: 'Model name',
            AddToPlan: 'Add to plan',
            AssemblyCount: 'Number of assemblies',
            AssemblyPlan: 'Assembly plan',
            EfficientTime: 'Operational assembly time',
            PartsLoadingText: 'Downloading parts...',
            DownloadParts: 'Downloading parts',
            RetryCounts: 'Number of retries',
            ProcessingTimeRetry: 'Processing time after last retry',
            ProcessingTime: 'Processing time',
            BeamScan: 'Scanning beam',
            PartsScan: 'Scanning parts',
            PartsMatch: 'Matching parts',
            SCANNING_BEAM_ACTION: 'Scanning beam',
            MATCHING_BEAM_ACTION: 'Matching beam',
            INSPECTION_BEAM_ACTION: 'Beam inspection',
            SCANNING_PARTS_ACTION: 'Scanning parts',
            MATCHING_PARTS_ACTION: 'Matching parts',
            INSPECTION_PARTS_ACTION: 'Parts inspection',
            PlannerTask: 'Planning task',
            ExecutorTask: 'Executor task',
            AfterTurn: 'After the turn',
            TurnCount: 'Total number of turns',
            AssembleStarted: 'Assembly started',
            AssembleStarted1: 'Assembly',
            AssembleStarted2: 'started',
            days: 'days',
            hours: 'hours',
            minutes: 'minutes',
            seconds: 'seconds',
            OverallAssembleTime: 'Overall assembly time',
            Archive: 'Archive',
            SCANNING_BEAM_SUCCESS:  'Scan beam successfully ended',
            unplug_scanner: 'Unplug the scanner!',
            all_parts: 'All parts',
            planned_parts: 'Planned parts',
            assembled_parts: 'Assembled parts',
            ScanResult: 'RESULTS OF SCANNING AND MATCHING PARTS',
            m: 'm',
            mm: 'mm',
            allChecked: 'all checked',
            beam_offset: 'Beam offset',
            holder2_position: 'Holder 2 position',
            holder3_position: 'Holder 3 position',
            holder1_offset: 'Holder 1 offset',
            holder2_offset: 'Holder 2 offset',
            holder3_offset: 'Holder 3 offset',
            Close: 'Close',
            GeneralSettings: "General Settings",
            ShowId: "Show ID",
            Model: 'Model',
            Models: 'Models',
            Tasks: 'Tasks',
            Status: 'Status',
            Service: 'Service',
            Settings: 'Settings',
            StatusHeader: 'Status',
            DownloadTimeHeader: 'Upload Time',
            CadModelHeader: 'Model name',
            WillBeAssembledHeader: 'Will be <br/> assembled',
            DetailsNumber: 'Details number',
            NumberOfTurns: 'Number of turns',
            OutOf: 'out of',
            PleasePressOnModel: 'Press on model',
            BeamAssemblingInProcess: 'Beam assembly in process',
            SimulateButton: 'Start simulation',
            SimulationInProcessButton: 'Simulation in process',
            StatusInProcess: 'Model in process',
            SubmitTaskButton: 'Submit task',
            DeleteButton: 'Delete model',
            ViewerTopView: 'Viewer from top',
            ViewerFrontView: 'Viewer from front',
            ViewerSideView: 'Viewer from side',
            racksAreEstablished: 'Racks are established',
            beamIsEstablished: 'Beam is Established',
            beamIsRotated: 'Beam rotated',
            detailsAreEstablished: 'Parts are established',
            LaunchButton: 'Start assembly',
            TablesNumber: 'Number of tables',
            SCANNING_PARTS: 'In progress: Scanning parts...',
            SCANNING_BEAM: 'In progress: Scanning beam...',
            Revert: "Attention! Red parts must be turned over.",
            AssemblyInProcess: 'In progress: Calculations of trajectories and assembly...',
            ScanningError: "Warning! Scanning error, please try again!",
            ReadyToAssembly: 'The beam is ready for assembly',
            AssemblyInProcessSuccess: "In progress: Calculations of trajectories and assembly...",
            ScanningParts2: 'In progress: Retrying scanning parts..',
            CheckingRevertedParts: "In progress: Checking parts...",
            NoTasksToBeAssembled: 'No tasks to process',
            DeleteProcessingTask: 'Cancel task',
            Interrupted: 'Assembly was interrupted',
            StopAssembling: 'Stop assembly',
            MakeReScan: 'Retry',
            MakeReScanBeam: 'Retry scan beam',
            MakeReScanParts: 'Retry scan parts',
            GettingInitialInformation: 'Getting initial information',
            SocketConnectionError: "Socket connection error",
            DetectedLessParts: 'Some parts were not found',
            ReverseStep: 'ROTATE THE BEAM',
            ReverseStep2: 'Check the indents and offsets - view from top',
            ContinueAssembling: 'Continue assembly',
            settingsModalHeader: '3D Model Settings',
            partsType: 'Parts type',
            viewerVisibility: 'Visibility',
            viewerwireframe: 'Show only wireframe',
            viewermaterialType: 'Material type',
            viewerOpacity: 'Opacity',
            viewermetalness: 'Metalness',
            viewercolor: 'Color',
            parts: 'Parts',
            missedParts: 'Missing parts',
            standardMesh: 'Standard',
            physicalMesh: 'Physical',
            normalMesh: 'Normal',
            basicMesh: 'Basic',
            depthMesh: 'Depth',
            lambertMesh: 'Lambert',
            matcapMesh: 'Matcap',
            phongMesh: 'Phong',
            toonMesh: 'Toon',
            RotationHeader: '# of <br/> rotations',
            CameraTopView: 'View from top',
            RackView: 'General view',
            CameraSideView: 'View from side',
            CameraFrontView: 'View from front',
            SubmitTask: 'Submit new task',
            SCANNING_BEAM_ERROR: 'Scanning beam error',
            SCAN_REVERSE_PARTS: 'Turn over the red parts',
            SCAN_LESS_PARTS: 'Missing parts',
            SCAN_UNMATCHED_PARTS: 'The number of parts does not match',
            SCANNING_PARTS_ERROR: 'Scanning parts error',
            STARTING_PLANNER_AND_LABEL: 'Calculation of trajectories and labeling',
            PLANNER_IS_RUNNING: 'In progress: Planner task',
            LABELING_IS_RUNNING: 'In progress: Labeling task',
            EXECUTOR_IDLE: 'EXECUTOR_IDLE',
            LABEL_ERROR: 'Labeling task error',
            PLANNER_OK: 'Planner task successfully ended',
            PLANNER_ERROR: 'Planner task error',
            SCANNING_BEAM_TIMEOUT_ERROR: 'Processing time limit exceeded',
            MATCHING_BEAM_TIMEOUT_ERROR: 'Processing time limit exceeded',
            INSPECTION_BEAM_TIMEOUT_ERROR: 'Processing time limit exceeded',
            SCANNING_PARTS_TIMEOUT_ERROR: 'Processing time limit exceeded',
            MATCHING_PARTS_TIMEOUT_ERROR: 'Processing time limit exceeded',
            INSPECTION_PARTS_TIMEOUT_ERROR: 'Processing time limit exceeded',
            LABELING_TIMEOUT_ERROR: 'Processing time limit exceeded',
            EXECUTOR_TIMEOUT_ERROR: 'Processing time limit exceeded',
            PLANNER_TIMEOUT_ERROR: 'Processing time limit exceeded',
            EXECUTOR_IS_RUNNING: 'In progress: executor task',
            EXECUTOR_OK: 'Assembly successfully ended',
            EXECUTOR_ERROR: 'Executor task error',
            motorsOn:'MotorsOn',
            ppToMain:'pp2main',
            motorsStop:'Stop',
            parking: 'Parking',
            DeleteButtonTask:'Cancel a task',
            Camera:'Camera',
            StatusSettings:'Check list:',
            TableStatusInput:'Number of tables with parts',
            archiveButton:'Archive a task',
            robotError:'The robot cannot continue assembly',
            SCANNING_BEAM_ROBOT_ERROR:'There was an error with the robot',
            SCANNING_PARTS_ROBOT_ERROR:'There was an error with the robot',
            LABELING_ROBOT_ERROR:'There was an error with the robot',
            EXECUTOR_ROBOT_ERROR:'There was an error with the robot',
            BeamMatch: 'Matching beam',
            EXECUTOR_SUCCESS: "Assembly successfully ended",
            GETTING_INITIAL_INFORMATION: "Getting task statuses...",
            LABELING_ERROR: "Labeling task error",
            MATCHING_BEAM_CONFIRMED: "Matching beam successfully ended",
            MATCHING_BEAM_ERROR: "Matching beam error",
            MATCHING_BEAM_IS_RUNNING: "In progress: Matching beam... ",
            INSPECTION_BEAM_CONFIRMED: "Inspection beam successfully ended",
            INSPECTION_BEAM_ERROR: "Inspection beam error",
            INSPECTION_BEAM_IS_RUNNING: "In progress: Inspection beam... ",
            MATCHING_LESS_PARTS_ERROR: "Missing parts",
            MATCHING_PARTS_CONFIRMED: "Matching parts successfully ended",
            MATCHING_PARTS_ERROR: "Matching parts error",
            MATCHING_PARTS_IS_RUNNING: "In progress: Matching parts... ",
            INSPECTION_PARTS_CONFIRMED: "Inspection parts successfully ended",
            INSPECTION_PARTS_ERROR: "Inspection parts error",
            INSPECTION_PARTS_IS_RUNNING: "In progress: Inspection parts... ",
            MATCHING_REVERSE_PARTS_ERROR: "Turn over the red parts",
            MATCHING_UNMATCHED_PARTS_ERROR: "The number of parts does not match",
            MakeReMatchBeam: "Retry matching beam",
            MakeReMatchParts: "Retry matching parts",
            MakeReInspectionBeam: "Retry inspection beam",
            MakeReInspectionParts: "Retry inspection parts",
            PLANNER_SUCCESS: "Planner successfully ended",
            SCANNING_BEAM_IS_RUNNING: "In progress: Scanning beam... ",
            SCANNING_PARTS_IS_RUNNING: "In progress: Scanning parts...",
            SCANNING_PARTS_SUCCESS: "Scanning parts successfully ended",
            SOCKET_CONNECTION_ERROR: "Server connection error",
            TableWarning: "Attention, there should be no gaps between tables.",
            matching_result: "Matching results",
            min: "min",
            tasks_loading_error: 'Error occurred while loading tasks',
            task_loading_error: 'Error occurred while loading a task',
            task_delete_error: 'Error occurred while cancelling a task',
            request_error: 'Failed to make request',
            submit_successfully: 'was successfully submitted',
            server_error: 'Server error occurred',
            unexpected_error: 'Something went wrong',
            alert_max_31_days: 'The number of days cannot exceed 31 days',
            models_loading_error: 'Error occurred while loading models',
            model_loading_error: 'Error occurred while loading a model',
            model_status_error: 'Error occurred while changing model status',
            model_delete_error: 'Error occurred while loading deleting a model',
            model_cancel_error: 'Error occurred while cancelling the simulation process',
            task_cancel_error: 'Error occurred while cancelling a task',
            beam_check_reverse_error: 'Error occurred while checking if beam need to turn over',
            token_invalid: 'Token invalid',
            unknown_status: 'Oops! Unknown status',
            stream_error: 'Error loading stream',
            data_loading_error: 'There was an error loading data',
            ply_loading_error: 'Error loading ply files',
            ply_loading_success: 'Ply files successfully downloaded',
            beam_vtk_loading_error: 'Error loading beam vtk file',
            parts_vtk_loading_error: 'Error loading parts vtk file',
            beam_vtk_loading_success: 'Beam vtk file successfully downloaded',
            parts_vtk_loading_success: 'Parts vtk file successfully downloaded',
            ExcludeParts: 'Exclude beam parts from assembly',
            skip_parts: 'excluded parts',
            excluded_parts: 'Excluded parts',
            units: 'units',
            Choose_model: 'Choose a model...',
            ReSimulateButton: 'Retry simulation',
            CancelSimulation: 'Cancel simulation',
            SUCCESSFULLY_ENDED: 'Assembly has successfully finished',
            Cancel: 'Cancel'
        }
    },
    RUS: {
        translation: {
            exclude_all: 'Исключить все',
            select_all: 'Выбрать все',
            Start: 'Запустить',
            openModelInfo: 'Информация о модели',
            tobe_assembled_parts: 'Будет собраны детали',
            matching_result: 'Результаты матчинга',
            MATCHING_BEAM_CONFIRMED: 'Матчинг балки успешно завершен',
            MATCHING_PARTS_CONFIRMED: 'Матчинг деталей успешно завершен',
            SCANNING_PARTS_SUCCESS: 'Сканирование деталей успешно завершено',
            RackPosition: 'Положение балки',
            IDLE: 'Ожидание...',
            MatchBeamResult: 'РЕЗУЛЬТАТ МАТЧИНГА БАЛКИ',
            DownloadFiles: 'Загрузка файлов',
            task_info: 'Task Info',
            CopyToClipboard: 'Task ID скопирован',
            Logout: 'Выход',
            min: "мин",
            avg_min_part: 'Среднее время на деталь',
            Quantity_rows: 'Количество строк',
            ContinueTask: 'Продолжить задание',
            PostponeAssembling: 'Отложить сборку',
            Part: 'Деталь',
            Quantity: 'Количество',
            openParts: 'Показать детали',
            total_parts: 'Всего собрано деталей',
            status: 'Статус',
            general_view: 'Общий вид',
            virtual_view: '3D вид',
            scheme: 'Чертеж',
            succ_end_but: 'Сборка успешна',
            cancel_end_but: 'Сборка не успешна',
            assembly_ended_modal_text: 'Сборка завершена. Выберите действие',
            weight: 'вес',
            Total_weight: 'Общий вес',
            kg: 'кг',
            Total_assembled: 'Всего сборок',
            show: 'Посмотреть',
            download: 'Загрузить',
            From_date: 'С даты',
            To_date: 'до даты',
            filter: 'Фильтр',
            graph3_title: 'Эффективность работы РТК, %(осн. минуты/480)',
            graph2_title: 'Количество основных минут работы РТК, мин/день',
            graph1_title: 'Вес сборок и количество собранных балок, шт/день',
            CurrentAssemblyCount: 'Текущее количество сборок',
            AssemblyCountChangeTo: 'Изменить количество сборок на',
            ModelName: 'Название модели',
            AddToPlan: 'Добавить в план',
            AssemblyCount: 'Количество сборок',
            AssemblyPlan: 'План сборки',
            EfficientTime: 'Полезное время сборки',
            PartsLoadingText: 'Загрузка деталей...',
            DownloadParts: 'Загрузка деталей',
            RetryCounts: 'Количество повторов',
            ProcessingTimeRetry: 'Время обработки после последнего повтора',
            ProcessingTime: 'Время обработки',
            BeamScan: 'Сканировние балки',
            BeamMatch: 'Матчинг балки',
            PartsScan: 'Сканирование деталей',
            PartsMatch: 'Матчинг деталей',
            PlannerTask: 'Планнер',
            ExecutorTask: 'Сборка',
            AfterTurn: 'После поворота',
            TurnCount: 'Количество поворотов',
            AssembleStarted1: 'Начало',
            AssembleStarted2: 'сборки',
            days: 'день',
            hours: 'час',
            minutes: 'минут',
            seconds: 'секунд',
            OverallAssembleTime: 'Общее время сборки',
            Archive: 'Архив',
            SCANNING_BEAM_SUCCESS: 'Сканер двутавра закончил работу',
            unplug_scanner: 'Отсоедини сканер!',
            allParts: 'Все детали',
            all_parts: 'Всего деталей',
            planned_parts: 'Запланированы детали',
            assembled_parts: 'Собрано деталей',
            ScanResult: 'РЕЗУЛЬТАТ СКАНА И МАТЧИНГА ДЕТАЛЕЙ',
            m: 'м',
            mm: 'мм',
            allChecked: 'все проверил',
            beam_offset: 'Вылет балки',
            holder2_position: 'Второй козлик на',
            holder3_position: 'Третий козлик на',
            holder1_offset: 'Отступ на первом',
            holder2_offset: 'Отступ на втором',
            holder3_offset: 'Отступ на третьем',
            Close: 'Закрыть',
            GeneralSettings: "Общие настройки",
            ShowId: "Показать ID",
            DeleteButtonTask:'Отменить задачу',
            Model: 'Модель',
            Models: 'Модели',
            Tasks: 'Задания',
            Status: 'В работе',
            Service: 'Сервис',
            Settings: 'Настройки',
            StatusHeader: 'Статус',
            DownloadTimeHeader: 'Время загрузки',
            CadModelHeader: 'Модель',
            WillBeAssembledHeader: 'Будет собрано',
            DetailsNumber: 'Деталей',
            NumberOfTurns: 'Поворотов',
            OutOf: 'из',
            PleasePressOnModel: 'Нажмите на модель',
            BeamAssemblingInProcess: 'Идет процесс сбора балки',
            SimulateButton: 'Симулировать',
            SimulationInProcessButton: 'Идет симуляция',
            StatusInProcess: 'Идет сборка модели',
            SubmitTaskButton: 'Переместить в задания',
            DeleteButton: 'Удалить модель',
            ViewerTopView: 'Вид сверху',
            ViewerFrontView: 'Вид спереди',
            ViewerSideView: 'Вид сбоку',
            racksAreEstablished: 'Козлики установлены',
            beamIsEstablished: 'Двутавр выставлен',
            detailsAreEstablished: 'Детали выложены',
            LaunchButton: 'Запустить',
            TablesNumber: 'Кол-во столов',
            SCANNING_PARTS: 'Выполняется: Сканирование деталей...',
            SCANNING_PARTS_IS_RUNNING: 'Выполняется: Сканирование деталей...',
            MATCHING_PARTS_IS_RUNNING: 'Выполняется: Матчинг деталей...',
            SCANNING_BEAM: 'Выполняется: Сканирование двутавра...',
            SCANNING_BEAM_IS_RUNNING: 'Выполняется: Сканирование двутавра...',
            MATCHING_BEAM_IS_RUNNING: 'Выполняется: Матчинг двутавра...',
            Revert: "Внимание! Необходимо перевернуть красные детали.",
            AssemblyInProcess: 'Выполняется: Расчеты траекторий и сборка...',
            ScanningError: "Внимание! Ошибка сканирования, повторите попытку!",
            ReadyToAssembly: 'Балка готова для сборки',
            AssemblyInProcessSuccess: "Выполняется: Расчеты траекторий и сборка...",
            ScanningParts2: 'Выполняется: Повторное сканирование..',
            CheckingRevertedParts: "Выполняется: Проверка деталей...",
            NoTasksToBeAssembled: 'Нет заданий для обработки',
            Interrupted: 'Сборка была прервана',
            StopAssembling: 'Остановить сборку',
            MakeReScan: 'Повторить',
            MakeReScanBeam: 'Повторить скан балки',
            MakeReScanParts: 'Повторить скан деталей',
            MakeReMatchBeam: 'Повторить матчинг балки',
            MakeReMatchParts: 'Повторить матчинг деталей',
            GETTING_INITIAL_INFORMATION: 'Получаем статус...',
            SOCKET_CONNECTION_ERROR: "Ошибка подключения к серверу",
            DetectedLessParts: 'Некоторые части не были найдены',
            ReverseStep: 'ПОВЕРНИТЕ ДВУТАВР НА 180 ГРАДУСОВ',
            ReverseStep2: 'Шаг 2 Проверить отступы и вылет - вид сверху',
            beamIsRotated: 'Двутавр повернул',
            ContinueAssembling: 'Продолжить сборку',
            settingsModalHeader: 'Настройки 3Д Модели',
            partsType: 'Тип деталей',
            viewerVisibility: 'Видимость',
            viewerwireframe: 'Отображать только каркас',
            viewermaterialType: 'Тип Материала',
            viewerOpacity: 'Прозрачность',
            viewermetalness: 'Металличность',
            viewercolor: 'Цвет',
            parts: 'Детали',
            all: 'Все',
            simulation_info: 'Информация о симуляции',
            Position: 'Позция',
            Beam_stand: 'Стойка для балок',
            Shift: 'Сдвиг',
            missedParts: 'Пропущенные детали',
            standardMesh: 'Стандартный',
            physicalMesh: 'Физический',
            normalMesh: 'Нормальный',
            basicMesh: 'Базовый',
            depthMesh: 'Глубокий',
            lambertMesh: 'Ламберт',
            matcapMesh: 'Матовый',
            phongMesh: 'Затененный',
            toonMesh: 'Мультяшный',
            RotationHeader: 'Поворотов',
            DeleteProcessingTask: 'Отменить задание',
            CameraTopView: 'ВИД СВЕРХУ',
            RackView: 'ОБЩИЙ ВИД',
            CameraSideView: 'ВИД СБОКУ',
            CameraFrontView: 'ВИД СПЕРЕДИ',
            TableWarning: 'Внимание между столами не должно быть пробелов',
            SubmitTask: 'Взять новое задание',
            SCANNING_BEAM_ERROR: 'Ошибка сканирования двутавра',
            MATCHING_BEAM_ERROR: 'Ошибка матчинга двутавра',
            SCAN_REVERSE_PARTS: 'Переверните детали красного цвета',
            MATCHING_REVERSE_PARTS_ERROR: 'Переверните детали красного цвета',
            SCAN_LESS_PARTS: 'Не хватает деталей',
            MATCHING_LESS_PARTS_ERROR: 'Не хватает деталей',
            SCAN_UNMATCHED_PARTS: 'Кол-во деталей не совпадает',
            MATCHING_UNMATCHED_PARTS_ERROR: 'Кол-во деталей не совпадает',
            SCANNING_PARTS_ERROR: 'Ошибка сканирования деталей',
            MATCHING_PARTS_ERROR: 'Ошибка матчинга деталей',
            STARTING_PLANNER_AND_LABEL: 'Расчет траекторий и клеймо',
            PLANNER_IS_RUNNING: 'Планер находится в работе',
            LABELING_IS_RUNNING: 'Ставим клеймо',
            EXECUTOR_IDLE: 'EXECUTOR_IDLE',
            LABEL_ERROR: 'Ошибка с клеймом',
            LABELING_ERROR: 'Ошибка с клеймом',
            PLANNER_OK: 'Планер закончил работу',
            PLANNER_SUCCESS: 'Планер закончил работу',
            PLANNER_ERROR: 'Ошибка с планером',
            SCANNING_BEAM_TIMEOUT_ERROR: 'Превышено время обработки',
            MATCHING_BEAM_TIMEOUT_ERROR: 'Превышено время обработки',
            SCANNING_PARTS_TIMEOUT_ERROR: 'Превышено время обработки',
            MATCHING_PARTS_TIMEOUT_ERROR: 'Превышено время обработки',
            LABELING_TIMEOUT_ERROR: 'Превышено время обработки',
            EXECUTOR_TIMEOUT_ERROR: 'Превышено время обработки',
            PLANNER_TIMEOUT_ERROR: 'Превышено время обработки',
            EXECUTOR_IS_RUNNING: 'Идет процесс сборки',
            EXECUTOR_OK: 'Сборка завершена',
            EXECUTOR_SUCCESS: 'Сборка завершена',
            EXECUTOR_ERROR: 'Ошибка при сборке',
            motorsOn:'MotorsOn',
            ppToMain:'pp2main',
            motorsStop:'Stop',
            parking: 'Parking',
            Camera:'Камера',
            StatusSettings:'Чек-лист:',
            TableStatusInput:'Кол-во столов с деталями',
            archiveButton:'Архивировать',
            robotError:'Робот не может продолжить сборку',
            SCANNING_BEAM_ROBOT_ERROR:'Случилась ошибка с роботом',
            SCANNING_PARTS_ROBOT_ERROR:'Случилась ошибка с роботом',
            LABELING_ROBOT_ERROR:'Случилась ошибка с роботом',
            EXECUTOR_ROBOT_ERROR:'Случилась ошибка с роботом',
            tasks_loading_error: 'Произошла ошибка при загрузке заданий',
            task_loading_error: 'Произошла ошибка при загрузке задания',
            task_delete_error: 'Произошла ошибка при удалении задания',
            request_error: 'Не удлось сделать запрос',
            submit_successfully: 'была успешно отправлена в работу',
            server_error: 'Случилась ошибка с сервером',
            unexpected_error: 'Что-то пошло нет',
            alert_max_31_days: 'Количество дней не может превышать 31 дней',
            models_loading_error: 'Произошла ошибка при загрузке моделей',
            model_loading_error: 'Произошла ошибка при загрузке модели',
            model_status_error: 'Произошла ошибка при смене статуса',
            model_delete_error: 'Произошла ошибка при удалении',
            model_cancel_error: 'Произошла ошибка при отмене процесса симуляции',
            task_cancel_error: 'При удалении задачи произошла ошбика',
            beam_check_reverse_error: 'Ошбика проверки переворота балки',
            token_invalid: 'Токен недействителен',
            unknown_status: 'Упс! незнакомый статус',
            stream_error: 'Ошибка при загрузке стрима',
            data_loading_error: 'Произошла ошибка при загрузке данных',
            ply_loading_error: 'Ошибка загрузки ply файлов',
            ply_loading_success: 'Ply файлы успешно загружены',
            vtk_loading_error: 'Ошибка загрузки vtk файла',
            vtk_loading_success: 'Vtk файл успешно загружен',
            ExcludeParts: 'Исключить детали из сборки',
            skip_parts: 'исключенные детали',
            excluded_parts: 'Исключены детали',
            close: 'закрыть',
            units: 'Единицы измерения',
            inches: 'дюймы',
            meters: 'метры',
            Choose_model: 'Выберите модель...',
            ReSimulateButton: 'Повторить симуляцию',
            CancelSimulation: 'Отменить симуляцию',
            SUCCESSFULLY_ENDED: 'Сборка успешно окончена',
            Cancel: 'Отменить'
        }
    }
}

const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'ENG'
i18n.use(initReactI18next).init({
    resources,
    lng: currentLanguage,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
