import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SinglePartViewer from "../../../../components/ThreeJsComponents/SinglePartViewer/SinglePartViewer";

function Parts(){
	const {t} = useTranslation();
	const {selectedModel, parts_to_skip} = useSelector((state => state.statusReducer))

	return <TableContainer component={Paper} style={{overflow: 'scroll', height: '100%'}}>
		<Table sx={{ minWidth: 250 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell align="left"><b>{t('Part ID')}</b></TableCell>
					<TableCell align="left"><b>{t('3DView')}</b></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{parts_to_skip && parts_to_skip.map((item) =>
					<TableRow
						key={item}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell align="left">{item}</TableCell>
						<TableCell align="left" style={{padding: 0, minHeight: '250px', border: '1px solid black'}}>
							<SinglePartViewer model={selectedModel.parts[item]} id={item}/>
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	</TableContainer>
}
export default Parts;