import {call, put, select, takeLatest} from "redux-saga/effects";
import {
    getPlannedTasksListFail,
    getPlannedTasksListSuccess,
    getReadyTasksListFail,
    getReadyTasksListSuccess, getSinglePlannedTaskFail, getSinglePlannedTaskSuccess,
    getSingleReadyTaskFail,
    getSingleReadyTaskSuccess,
    setListOfMissedParts, setListOfMissedPartsPlanned,
    submitProcessingTaskFail,
    submitProcessingTaskSuccess,
    taskDeleteFail,
    taskDeleteSuccess, unselectPlannedTask,
} from "./actions";
import {
    CONTINUE_TASK_PROCESSING_REQUEST,
    GET_PLANNED_TASKS_REQUEST,
    GET_READY_TASKS_REQUEST, GET_SINGLE_PLANNED_TASK_REQUEST,
    GET_SINGLE_READY_TASK_REQUEST, SET_PLANNED_TASK_REQUEST,
    SUBMIT_TASK_PROCESSING_REQUEST,
    TASK_DELETE_MODEL_REQUEST
} from "./types";
import {
    continuePendingTask,
    createPendingTask,
    getPlannedTasksListRequest, getReadyTasksList,
    setPlannedTaskRequest
} from "./requests";
import {turnOnViewerLoading, updateModelStateInReducer} from "../modelsReducer/actions";
import {getSingleModelTask, updateSimulationTaskStatus} from "../modelsReducer/requests";
import {sendGeneralMessage, setFirstTimeRendered, setRedirectPath} from "../layoutReducer/actions";
import {IDLE, MOUNTING} from "../../../components/StatusesComponets/statusConstants";
import {deinitializeStatusPage, resetChecks, setTaskStatus} from "../statusReducer/actions";
import i18n from "../../../i18n";

function* getPlannedTasks() {

    try {
        const {data} = yield call(getPlannedTasksListRequest)
        let filteredData = []
        if (data && data.length) {
            filteredData = data
        }
        yield put(getPlannedTasksListSuccess(filteredData))


    } catch (e) {
        yield put(getPlannedTasksListFail())
        yield put(sendGeneralMessage(i18n.t("tasks_loading_error"), 'error'))
    }
}
function* getReadyTasks(action) {
    try {
        const {data} = yield call(getReadyTasksList, action.filter)
        let filteredData = []
        if (data && data.tasks && data.tasks.length) {
            filteredData = data.tasks
        }
        yield put(getReadyTasksListSuccess(filteredData, data.total_pages || 0))
    } catch (e) {
        yield put(getReadyTasksListFail())
        yield put(sendGeneralMessage(i18n.t("tasks_loading_error"), 'error'))
    }
}

function* setPlannedTask(action) {
    try {
        yield call(setPlannedTaskRequest, action.selectedTaskName, action.count)
    } catch (e) {
        yield put(sendGeneralMessage(i18n.t('request_error'), 'error'))
    }
    finally {
        yield put(unselectPlannedTask())
        action.loadPlanned ? action.loadPlanned() : console.log('no need to load planned')
        action.handleClose ? action.handleClose() : console.log('no modal')
        action.reloadPage ? action.reloadPage() : console.log('no need to reload')
    }
}

function* getSingleTask(action) {
    yield put(turnOnViewerLoading())
    try {
        const {data} = yield call(getSingleModelTask, action.payload)
        const {readyTasksList} = yield select((state) => state.readyTasksReducer)
        const chosenItem = readyTasksList.find((item) => item.name === action.payload)
        const missedParts = chosenItem?.planning_info?.total_list_of_missed_parts ? chosenItem.planning_info.total_list_of_missed_parts : []
        yield put(setListOfMissedParts(missedParts))
        yield put(getSingleReadyTaskSuccess(data))
    } catch (e) {
        yield put(getSingleReadyTaskFail())
        yield put(sendGeneralMessage(i18n.t('task_loading_error'), 'error'))
    }
}

function* getSinglePlannedTask(action) {
    try {
        yield put(turnOnViewerLoading())
        const {data} = yield call(getSingleModelTask, action.payload)
        const {plannedTasksList} = yield select((state) => state.readyTasksReducer)
        const chosenItem = plannedTasksList.find((item) => item.name === action.payload)
        const missedParts = chosenItem?.planning_info?.total_list_of_missed_parts ? chosenItem.planning_info.total_list_of_missed_parts : []
        yield put(setListOfMissedPartsPlanned(missedParts))
        yield put(getSinglePlannedTaskSuccess(data))
    } catch (e) {
        yield put(getSinglePlannedTaskFail())
        yield put(sendGeneralMessage(i18n.t('task_loading_error'), 'error'))
    }
}

function* deleteTaskSaga(action) {
    try {
        yield call(updateSimulationTaskStatus, action.payload, 'Ready')
        yield put(taskDeleteSuccess(action.payload))
        yield put(setFirstTimeRendered())
        yield put(updateModelStateInReducer(action.payload, 'Ready'))

    } catch (e) {
        yield put(taskDeleteFail())
        yield put(sendGeneralMessage(i18n.t('task_delete_error'), 'error'))
    }

}


function* submitProcessingTaskSaga(action) {
    try {
        let task = null
        if(action.planned){
            const {selectedPlannedTask} = yield select((state) => state.readyTasksReducer)
            task = selectedPlannedTask
        }else{
            const {selectedTask} = yield select((state) => state.readyTasksReducer)
            task = selectedTask
        }
        const newTask = {
            task_id: "",
            upload_time: "",
            assembly_name: task?.file?.name,
            statuses:{
                taskStatus: MOUNTING,
                beam_action: IDLE,
                parts_action: IDLE,
                scanningBeamStatus: IDLE,
                matchingBeamStatus: IDLE,
                scanningPartsStatus: IDLE,
                matchingPartsStatus: IDLE,
                labelingStatus: IDLE,
                executorStatus: IDLE,
                plannerStatus: IDLE,
            },
            scanning_info: {},
            parts_to_reverse: {}
        };
        yield call(createPendingTask, newTask)
        yield put(submitProcessingTaskSuccess())
        yield put(resetChecks())
        yield put(setTaskStatus(MOUNTING))
        yield put(deinitializeStatusPage())
        yield put(sendGeneralMessage(`${i18n.t('Model')} ${task?.file?.name} ${i18n.t('submit_successfully')}`, 'success'))
        yield put(setRedirectPath('/status'))

    } catch (e) {
        yield put(submitProcessingTaskFail())
        yield put(sendGeneralMessage(i18n.t('task_loading_error'), 'error'))
    }

}

function* continueProcessingTaskSaga(action) {
    try {
        yield call(continuePendingTask, action.payload.task_id)
        yield put(submitProcessingTaskSuccess())
        yield put(deinitializeStatusPage())
        yield put(sendGeneralMessage(`${i18n.t('Model')} ${action.payload.model_name} ${i18n.t('submit_successfully')}`, 'success'))
        yield put(setRedirectPath('/status'))

    } catch (e) {
        yield put(submitProcessingTaskFail())
        yield put(sendGeneralMessage(i18n.t('task_loading_error'), 'error'))
    }

}
export default function* readyTasksSaga() {
    yield takeLatest(GET_READY_TASKS_REQUEST, getReadyTasks)
    yield takeLatest(GET_PLANNED_TASKS_REQUEST, getPlannedTasks)
    yield takeLatest(GET_SINGLE_READY_TASK_REQUEST, getSingleTask)
    yield takeLatest(GET_SINGLE_PLANNED_TASK_REQUEST, getSinglePlannedTask)
    yield takeLatest(TASK_DELETE_MODEL_REQUEST, deleteTaskSaga)
    yield takeLatest(SUBMIT_TASK_PROCESSING_REQUEST, submitProcessingTaskSaga)
    yield takeLatest(CONTINUE_TASK_PROCESSING_REQUEST, continueProcessingTaskSaga)
    yield takeLatest(SET_PLANNED_TASK_REQUEST, setPlannedTask)
}
