import React, {useContext} from 'react';
import {useSelector} from "react-redux";
import {Button, CircularProgress} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import {useTranslation} from "react-i18next";
import {StyledPlanner} from "./StyledPlanner";

function Planner() {
  const {t} = useTranslation()
  const {parts_statuses, taskId: task_id} = useSelector((state) => state.statusReducer)
  const {state: {ws},} = useContext(SocketContext);

  return (
    <StyledPlanner>
      <div style={{padding: '10px', height: '20px'}}>PLANNER STATUS:</div>
      <hr style={{margin: 0}}/>
      <div className={"planner_body"}>
        <table className={"parts_table"}>
          <tbody>
          <tr>
            <th>Part ID</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          {parts_statuses ? parts_statuses.map((item) => {
            switch (item.status) {
              case 'ERROR':
              case 'FAILED':
                return <tr key={item.id}>
                  <td>{item.id}</td>
                  <td><ErrorOutlineIcon style={{color: 'red'}}/>{item.status}</td>
                  <td><Button color={'success'} variant={'contained'} size={"small"}
                              style={{width: '100px'}}
                              onClick={() => {
                                ws.emit('start_single_planner', task_id, item.id)
                              }}> {t('MakeReScan')}</Button></td>
                </tr>
              case 'WAITING':
                return <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <CircularProgress size={10} style={{color: 'white', margin:'0px 5px'}}/>
                    {item.status}
                  </td>
                  <td><Button color={'error'} variant={'contained'} size={"small"}
                              style={{width: '100px'}}
                              onClick={() => {
                                ws.emit('cancel_task', task_id, 'task_single_planner', item.id)
                              }}> {t('Cancel')}</Button></td>
                </tr>
              case 'PLANNED':
                return <tr key={item.id}>
                  <td>{item.id}</td>
                  <td><CheckCircleOutlineIcon style={{color: 'green'}}/>{item.status}</td>
                  <td></td>
                </tr>
              case 'SKIPPED':
                return <tr key={item.id}>
                  <td>{item.id}</td>
                  <td><RemoveCircleOutlineIcon style={{color: 'yellow'}}/>{item.status}</td>
                  <td></td>
                </tr>
              default:
                return <></>
            }
          }) :
            <tr>
              <td colSpan={3} style={{textAlign: 'center'}}>Oops empty...</td>
            </tr>
          }
          </tbody>
        </table>
      </div>
    </StyledPlanner>
  );
}

export default Planner;
