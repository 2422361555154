import React, {useContext, useEffect, useState} from 'react'
import {Button, Typography} from "@mui/material";
import StyledCheckbox from "../../../components/StyledInputs/StyledCheckbox";
import {useTranslation} from "react-i18next";
import {SocketContext} from "../../../redux/customReducer/SocketContext";
import {useDispatch, useSelector} from "react-redux";
import {clearState} from "../../../redux/reducers/downloaderReducer/actions";
import check_role from "../../../utils/utils";
import {LOAD_FILES_BEAM} from "../../../redux/reducers/downloaderReducer/reducer";
import VTKViewer from "../../../utils/VTKViewer";
import Inspection from "./Inspection/Inspection";
import {MATCHING_BEAM_SUCCESS} from "../../../components/StatusesComponets/statusConstants";

export default function   RackViewer({hide_buttons}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [allChecked, setAllChecked] = useState(false)
  const {state: {ws}} = useContext(SocketContext);
  const {taskId: task_id, matchingBeamStatus} = useSelector((state) => state.statusReducer)
  const {username} = useSelector((state) => state.cachedReducer)
  const {beam_files, loading_beam} = useSelector((state) => state.downloaderReducer)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if(task_id){
      dispatch({type: LOAD_FILES_BEAM, dispatch, task_id});
    }
    return () => {
      dispatch(clearState())
    };
  }, [task_id])

  return (
    <div style={{ height: '100%' }}>
      <div style={{
        height: '100%',
        flexBasis: '25%'
      }}>
        {/*<ModalCheck/>*/}
        <div style={{
          backgroundColor: '#141414',
          borderRadius: '8px',
          height: 'calc(100% - 20px)',
          padding: '10px'
        }}>
          <div style={{height: '29px'}}>
            <Typography color={'white'} style={{display: "inline-block", fontSize: '16px'}}> {t('MatchBeamResult')}</Typography>
            <Button variant={'contained'} color={"secondary"} style={{marginLeft: '10px', padding: '2px 10px'}} size={"small"}
                    onClick={()=>{handleOpen()}}>{t('Inspection')}
            </Button>
          </div>
          <div style={{textAlign: 'center', height: 'calc(100% - 116px)'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <VTKViewer files={beam_files} class_name="beam_VTK_viewer" loading={loading_beam}/>
            </div>
          </div>

          {!check_role(username) || hide_buttons ? '' :
            <div style={{textAlign: 'center', height: '86px'}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <StyledCheckbox color={'secondary'} checked={allChecked}
                                onChange={(event, checked) => {setAllChecked(checked) }}/>
                <Typography color={allChecked ? '#54fcef' : 'white'}
                            fontSize={'1.6vh'}>{t('allChecked')}</Typography>
              </div>
              <Button variant={'contained'} color={"secondary"} style={{marginTop: '10px'}}
                      onClick={()=>{ ws.emit('inspection_beam_end', task_id) }}
                      disabled={!(allChecked)}>{t('ContinueAssembling')}
              </Button>
              <Button color={'success'} variant={'contained'}
                      style={{flexBasis: '250px', marginLeft: '20px', marginTop: '10px'}}
                      onClick={() => {
                        ws.emit('start_scan_beam', task_id)
                      }}> {t('MakeReScanBeam')}</Button>
              <Button color={'success'} variant={'contained'}
                      style={{flexBasis: '250px', marginLeft: '20px', marginTop: '10px'}}
                      onClick={() => {
                        ws.emit('start_match_beam', task_id)
                      }}> {t('MakeReMatchBeam')}</Button>
              <Button color={'success'} variant={'contained'}
                      style={{flexBasis: '250px', marginLeft: '20px', marginTop: '10px'}}
                      onClick={() => {
                        ws.emit('start_inspection_beam', task_id)
                      }}> {t('MakeReInspectionBeam')}</Button>
            </div>
          }
          {hide_buttons || matchingBeamStatus == MATCHING_BEAM_SUCCESS ?
            <Inspection open={open} handleClose={handleClose} task_id={task_id}/>
            :
            <></>
          }
        </div>
      </div>
    </div>
  )
}
